/* owl carousel fix */

.grabbing {
    cursor: move;
}

.cover {
    overflow: hidden;
    position: relative;
    height: 90vh;
    min-height: 400px;
    max-height: 460px;
    @media (min-width: $screen-xlg-min) {
      max-height: 720px;
    }
    &.center {
        text-align: center;
    }
    .bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0;
    }
    .content {
        position: absolute;
        transform: translateY(-50%);
        top: 37%;
        left: 0;
        right: 0;
        max-width: 100%;
        margin: 0 auto;
        padding: 0 30px;
        h1 {
          font-size: 2rem;
          line-height: 2rem;
          @media (min-width: $screen-sm-min) {
            font-size: 3rem;
          }
        }
        p {
          max-width: 420px;
          margin: 18px auto;
        }
    }
}

.multi-cover, .multi-cover .owl-wrapper-outer, .multi-cover .owl-wrapper, .multi-cover .owl-item {
    height: 100%;
}

.multi-cover {
    .cover {
        .content {
            opacity: 0;
        }
    }
    .active .cover .content {
        opacity: 1;
    }
    &:hover .owl-buttons {
        opacity: 1;
    }
    .owl-buttons {
        opacity: 0;
        transition: ease all 500ms;
    }
    .owl-prev, .owl-next {
        color: #fff;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        left: 0px;
        opacity: 0.4;
        @media (min-width: $screen-sm-min) {
            left: 20px;
        }
        i {
            font-size: 3em;
            transition: ease all 500ms;
        }
        &:hover {
            color: $color-dark;
            opacity: 1;
        }
    }
    .owl-next {
        left: auto;
        right: 0px;
        @media (min-width: $screen-sm-min) {
            left: auto;
            right: 20px;
        }
    }
    .owl-pagination {
        position: absolute;
        bottom: 0;
        text-align: center;
        width: 100%;
        .owl-page {
            display: inline-block;
            width: 10px;
            height: 10px;
            background: #fff;
            border-radius: 10px;
            margin: 4px;
            opacity: 0.4;
            transition: ease all 500ms;
            &.active {
                opacity: 1;
            }
            &:hover {
                color: $color-dark;
                opacity: 1;
            }
        }
    }
}
