.sfPageContainer .header {
  display: none !important;
}

.header-fixed+.sfContentBlock {
  padding-top: 84px;
  @media (min-width: $screen-sm-min) {
    padding-top: 124px;
  }
}

.sf_colsOut.container {
  padding: 60px 15px;
}

.sfContentBlock .img-responsive {
  width: 100%;
}
