.button {
    display: inline-block;
    padding: 12px 20px;
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    border: 1px solid transparent;
    background: $color-brand;
    color: #fff;
    border-radius: $border-radius;
    box-shadow: 0px 4px 0px 0px rgba(0,0,0,0.2);
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.37);

    &.secondary {
        background: $color-secondary;
    }
    &:hover {
        color: #fff;
    }
    &.small {
        padding: 8px 16px;
        font-size: 0.8em;
    }
    &.medium {
        padding: 12px 18px;
        font-size: 1.5em;
    }
    &.large {
        padding: 16px 22px;
        font-size: 2em;
    }
    &.outline {
        background: transparent;
        border: 1px solid $color-dark;
        color: $color-dark;
    }
    &.round {
        border-radius: $border-radius;
    }
    &.rounder {
        border-radius: 24px;
    }
}
