.home-grid {
  .grid-item {
    display: table;
    width: 100%;
    text-align: center;
    min-height: 230px;
    padding: 10px;
    .sf_colsIn {
      display: table-cell;
      text-align: center;
      vertical-align: middle;
    }
    .grid-content {
      display: block;
      width: 100%;
    }
    h2 {
      margin-top: 0;
      font-size: 1.2rem;
    }
    .button {
      font-size: 1rem;
    }
  }
  .grid-construction {
    background: url('../img/grid/construction.jpg') no-repeat top center / cover;
    h2 {
      color: #d2ac03;
    }
    .button {
      background: #f3b832;
    }
  }
  .grid-bus {
    background: url('../img/grid/bus.jpg') no-repeat top center / cover;
  }
  .grid-chalk {
    background: url('../img/grid/chalk.jpg') no-repeat top center / cover;
    color: rgba(255, 255, 255, 0.49);
    h2 {
      color: #fff;
    }
    .button {
      background: #9eb62f;
    }
  }
  .grid-road {
    background: url('../img/grid/road.jpg') no-repeat top center / cover;
  }
  .grid-stencil {
    background: url('../img/grid/stencil.jpg') no-repeat top center / cover;
    color: rgba(255, 255, 255, 0.49);
    h2 {
      color: #fff;
    }
    .button {
      background: #f3b832;
    }
  }
  .grid-stenci-grid {
    background: url('../img/grid/stenci-grid.jpg') no-repeat top center / cover;
  }
}
