header.page {
  padding: 40px 0 20px 0;
  color: #fff;
  h1 {
    font-size: 27pt;
    margin: 0;
    color: #fff;
  }
  &.dark {
    background: $color-dark;
    
  }
  &.blue {
    background: $color-blue;
    
  }
  &.orange {
    background: $color-orange;
    
  }
  &.green {
    background: $color-green;
    
  }
  &.purple {
    background: $color-purple;
    
  }
  &.yellow {
    background: $color-yellow;
    
  }
  &.red {
    background: $color-red;
    
  }
}
