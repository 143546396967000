.nav-toggle {
  width: 20px;
  position: relative;
  float: right;
  z-index: 22;
  cursor: pointer;
  box-sizing: content-box;
  padding: 22px 16px;
  @media (min-width: $screen-sm-min) {
    display: none;
  }
  .bar {
    margin-bottom: 3px;
    height: 2px;
    display: block;
    content: " ";
    background: $color-dark;
    width: 100%;
    transition: ease transform 500ms;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &.active {
    padding: 22px 15px;
    .bar {
      margin-bottom: 0;
    }
    .bar:first-child {
      opacity: 0;
    }
    .bar:nth-child(2) {
      transform: rotateZ(-45deg);
      margin-bottom: 0;
    }
    .bar:nth-child(3) {
      transform: rotateZ(45deg);
      margin-bottom: 0;
      margin-top: -2px;
    }
  }
}
.main-nav {
  max-height: 0;
  transition: max-height 500ms ease;
  overflow: scroll;
  clear: both;

  .container {
    position: relative;
  }

  &.show {
    max-height: 100vh;
    &.mobile-full-height {
      height: 100vh;
    }
  }

  @media (min-width: $screen-sm-min) {
    max-height: none;
    overflow: visible;
    clear: none;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      >a {
        display: block;
        padding: 18px 15px;
        color: #717171;
        @media (min-width: $screen-sm-min) {
          padding: 20px 10px;
        }
        &:hover {
          color: $color-brand;
        }
      }
      @media (min-width: $screen-sm-min) {
        display: block;
        float: left;
      }
      &:hover {
        >ul {
          max-height: 600px;
          overflow: visible;
        }
      }
    }
    ul {
      max-height: 0;
      overflow: hidden;
      transition: all ease 500ms;
      z-index: 999;
      background: $color-dark;
      @media (min-width: $screen-sm-min) {
        position: absolute;
      }
      li {
        float: none;
        display: block;
        a {
          color: #fff;
          padding: 10px 15px;
          &:hover {
            color: #fff;
            background: $color-secondary;
          }
        }
      }
      ul {
        background: rgb(121, 137, 162);
        @media (min-width: $screen-sm-min) {
          top: 0px;
          left: 100%;
          position: absolute;
        }
      }
    }
  }
}
