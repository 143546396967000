footer {
  padding: 50px 0;
  font-size: 0.9em;
  text-align: center;
  @media (min-width: $screen-sm-min) {
    text-align: left;
  }
  &.dark {
    background: #4f5d73;
    border-bottom: 5px solid #37475e;
    color: #8699b6;
    h2 {
      color: #fff;
    }
    a {
      color: #70819c;
      margin-right: 20px;
      &:hover {
        color: #fff;
      }
    }
    .button {
      color: #fff;
    }
  }
  &.compact {
    padding: 20px 0;
  }
  &.simple {
    text-align: center;
    p {
      margin: 2em 0;
    }
  }
  .logo {
    margin: 0 10px;
    img {
      width: 70px;
    }
  }
  .footer-nav {
    margin: 0;
    padding: 0;
    text-align: center;
    list-style: none;
    >li {
      display: inline-block;
      >a {
        padding: 0 12px;
      }
    }
  }
  p, .footer-nav {
    margin: 14px 0;
    padding-top: 6px;
  }
}
