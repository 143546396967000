@import "variables.css";
@import "effects/parallax.css";
@import "base/fonts.css";
@import "base/header.css";
@import "base/footer.css";
@import "base/cover.css";
@import "base/section.css";
@import "base/buttons.css";
@import "components/main-nav.css";
@import "components/page-header.css";
@import "components/call-to-action.css";
@import "components/home-grid.css";

@import "sitefinity.css";

html, body {
	height: 100%;
}
a {
  transition: ease all 300ms;
	&:focus, &:hover {
		text-decoration: none;
	}
}
