.header {
  background: #fff;
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 999;
  @media (min-width: $screen-sm-min) {
    .secondary-nav {
      top: -63px;
      right: 0;
      font-size: 0.9em;
      position: absolute;
      >li>a {
        font-size: 1em;
      }
    }
  }

  .font-size-decrease,
  .font-size-increase {
    float: right;
  }

  .search {
    padding: 16px 10px;
    transition: ease all 500ms;
    max-width: 50px;
    overflow: hidden;

    input[type=search] {
      background: #2f2f2e;
      color: #fff;
      border: 0;
      padding: 5px 10px;
      float: left;
      display: none;
    }
    .expand {
      cursor: pointer;
      float: right;
    }

    &.expanded {
      max-width: none;
      min-width: 200px;
      input[type=search] {
        max-width: 85%;
        display: block;
      }
    }
  }
  .delays a {
    color: $color-orange;
  }
  .text {
    transition: ease all 500ms;
    display: none;
    @media (min-width: $screen-sm-min) {
      display: block;
      padding: 20px 10px;
    }
  }
  .logo {
    z-index: 1;
    float: left;
    position: relative;
    a {
      padding: 6px 10px;
      display: inline-block;
      font-size: 1.2em;
      @media (min-width: $screen-sm-min) {
        padding: 8px 10px;
        font-size: 2em;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
  &.header-fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
  }
  &.scrolled {
    background: #fff;
    @media (min-width: $screen-sm-min) {
      .logo a {
        padding: 6px 10px;
        font-size: 1.3em;
      }
      .text, nav>ul>li>a {
        padding: 10px;
      }
    }
  }
  &.dark {
    background: $color-dark;
    .text {
      color: #b1b1b1;
    }
    a {
      color: #fff;
    }
    .nav-toggle .bar {
      background: #fff;
    }
    nav>ul>li>a:hover {
      background: #b1b1a1;
    }
  }
  &.header-air {
    a {
      color: #fff;
    }
    .nav-toggle .bar {
      background: #fff;
    }
    &.scrolled {
      a {
        color: #000;
      }
      .nav-toggle .bar {
        background: #000;
      }
    }
  }
}
