.parallax2 {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	transform: translate3d(0px, 0px, 0px);
	img {
		transform: translate3d(0px, 0px, 0px);
		display: block;
		height: 100%;
	}
}
