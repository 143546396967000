section.page {
    padding: 20px;
    @media (min-width: $screen-sm-min) {
        padding: 60px 0;
    }
    .content {
        position: relative;
    }
}

.header-fixed+section.section {
    padding-top: 84px;
    @media (min-width: $screen-sm-min) {
        padding-top: 124px;
    }
}

section.section {
    position: relative;
    overflow: hidden;
    padding: 20px;
    @media (min-width: $screen-sm-min) {
        padding: 60px 0;
    }
    &.just-lead, &.spacey {
        padding: 40px;
        @media (min-width: $screen-sm-min) {
            padding: 120px 0;
        }
    }
    .bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0;
        overflow: hidden;
        z-index: 21;
        >img {
            min-width: 100%;
        }
    }
    .container-fluid, .container {
        position: relative;
        z-index: 22;
    }
    &.center {
        text-align: center;
    }
    &.full-screen {
        min-height: 100vh;
    }
    &.dark {
        color: #fff;
        background: $section-dark-bg-color;
        h1, h2, h3 {
          color: #fff;
        }
    }
    &.spacey {
        p {
            line-height: 2em;
        }
    }
    &.just-lead {
        text-align: center;
        p {
            font-size: 1.5em;
            max-width: 800px;
            margin: 0 auto;
            &+p {
                margin-top: 2em;
            }
        }
    }
}
