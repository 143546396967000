html {
  font-size: $font-body-size;
}

body {
  font-size: 1rem;
  font-family: $font-body;
  color: $color-font;
  -webkit-font-smoothing: antialiased;
}

p {
  margin: 0 0 1em 0;
  &.lead {}
}

.brow {
  margin-bottom: 0;
  &+* {
    margin-top: 0;
  }
}

h1 {
  font-size: 3em;
  color: $color-brand;
}

h2 {
  color: $color-brand;
}
